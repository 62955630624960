
import { defineComponent } from 'vue'
import BaseSvg from '@/components/base/icons/BaseSvg.vue'
export default defineComponent({
  components: { BaseSvg },
  props: {
    d: {
      type: String,
      required: true,
    },
  },
})
